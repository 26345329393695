@import 'styles/mixins';

.modal-listings-dialog {
  @include breakpoint-md {
    max-width: inherit;
    margin-top: 80px;
  }
}

.transparent {
  background: transparent !important;
}

.overflow-y-visible {
  overflow-y: visible;
}

.modal-listings-backdrop {
  &:global(.show) {
    opacity: 0.9;
  }
}

.images-container {
  max-width: 925px;
}

.listingTag:hover {
  transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
  transform: scale(1.08);
}
