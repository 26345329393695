.listing-card {
  border-radius: 24px;
}

.seller-listing-hover:hover {
  background: white;
  box-shadow: 0 4px 33px 0 rgb(0 0 0 / 0.11);
  transition: all 300ms ease-in-out;
}

.opacity-transition {
  -webkit-transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
