@import 'styles/mixins';

$carousel-desktop-height: 500px;
$carousel-mobile-height: 400px;

.carousel {
  position: relative;

  &-placeholder {
    height: $carousel-mobile-height;

    @include breakpoint-md {
      height: $carousel-desktop-height;
    }
  }

  &-item-container {
    height: $carousel-mobile-height;

    @include breakpoint-md {
      height: $carousel-desktop-height;
    }
  }

  .navigationContainer {
    opacity: 0.5;
    z-index: 2;
    width: 15%;
    transition: opacity 0.15s ease;

    &:hover {
      opacity: 1;
    }
  }

  .navigation {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  :global {
    .carousel-indicators {
      background: rgb(0 0 0 / 0.2);
      width: max-content;
      margin: 0 auto;
      border-radius: 20px;
      padding: 0 10px;
      bottom: 50px;
      border: none;
      height: 18px;
      display: flex;
      align-items: center;

      button {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin: 0 6px;
        background-color: #ccc;

        &.active {
          background-color: #fff;
        }
      }
    }
  }
}

.banner-item {
  &-container {
    top: 29%;

    @include breakpoint-md {
      top: 45%;
    }
  }

  &-background {
    background-size: auto 100%;
    background-position: right -195px bottom 0;

    @include breakpoint-sm {
      background-position: right 0 bottom 0;
    }

    @include breakpoint-md {
      background-size: auto 113%;
      background-position: right -0 top -64px;
    }
  }

  &-foreground {
    background-size: auto 100%;
    background-position: right -195px bottom 0;

    @include breakpoint-sm {
      background-position: right 0 bottom 0;
    }

    @include breakpoint-md {
      background-size: auto 113%;
      background-position: right -0 top -64px;
    }
  }
}
