@import 'styles/mixins';

.collections-model {
  color: rgb(255 252 249 / 1);
  font-size: 18px;

  @include breakpoint-sm {
    font-size: 14px;
  }

  .background {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .description {
    color: rgb(255 252 249 / 0.4);
  }
}

.collections-listing {
  .description {
    color: rgb(255 252 249 / 0.6);
  }
}

.collections-carousel {
  padding-bottom: 1.75rem !important;
}

.collections-carousel-item {
  -webkit-transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.collections-carousel-item::after {
  opacity: 0;
  -webkit-transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:
    transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.collections-carousel-item:hover {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.15);
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
}

.collections-carousel-item:hover::after {
  opacity: 1;
}

.bg-transparent {
  background-color: transparent !important;
}
