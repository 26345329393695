@import "styles/mixins";

.default-card-item-container {
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
}

.default-card-item-heading-subtitle {
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
}