@import 'styles/mixins';

.container {
  display: inline-block !important;
  padding: 0.25rem 0.5rem;
  
  @include breakpoint-sm {
    padding: 0.4rem 0.75rem;
  }
}

.variant-light {
  color: rgb(23 22 20 / 0.6);
  background-color: rgb(23 22 20 / 0.06) !important;
}
