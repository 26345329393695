@import 'styles/mixins';

.modelSlide {
  width: 48%;

  @include breakpoint-xs {
    width: 22%;
  }
}

.shadowInTablet {
  box-shadow: 0 0.5rem 1rem rgba(#000, 0.15);

  @include breakpoint-md {
    box-shadow: none;
  }
}

.gradientLeft {
  z-index: 2;
  background: linear-gradient(to left, #fffcf9, rgb(255 252 249 / 0));
}

.darkGradientLeft {
  z-index: 2;
  background: linear-gradient(to left, #1b1c1d, rgb(27 28 29 / 0));
}

.gradientRight {
  z-index: 2;
  background: linear-gradient(to right, #fffcf9, rgb(255 252 249 / 0));
}

.darkGradientRight {
  z-index: 2;
  background: linear-gradient(to right, #1b1c1d, rgb(27 28 29 / 0));
}

.carousel-details {
  .carousel-slide {
    height: 425px;
  }

  :global {
    .carousel-indicators {
      background: transparent;
      bottom: 20px;

      button {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin: 0 6px;
        background-color: #ccc;

        &.active {
          background-color: #000;
        }
      }
    }
  }
}

.carousel-swiper {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

  @include breakpoint-md {
    padding-left: 1.8rem !important;
    padding-right: 1.8rem !important;
  }
}
